.socialTool {
  display: flex;
  gap: 60px;
  padding: 40px 24px;
}

.socialToolPreview {
  width: 800px;
}

.socialToolControls {
  width: calc(100% - 860px);
}

.socialPreviewContainer {
  overflow: hidden;
  width: 800px;
  height: 800px;
}

.socialCard {
  overflow: hidden;
  width: 800px;
  height: 800px;
  position: relative;
  display: flex;
}

.cardContentContainer {
  max-width: 660px;
  max-height: 660px;
  margin: auto;
}

.quoteContainer {
  font-family: 'sabon-next-italic'
}

.quoteMeta {
  margin-top: 40px;
  text-align: right;
}

.quoteAttribution {
  font-size: 30px;
}

.quoteWork {
  font-size: 30px;
}

.quoteTranslator {
  font-size: 20px;
}

.flex-layout {
  display: flex;
  gap: 40px;
}

.flex-one {
  flex: 1;
}

.borderBox {
  width: 720px;
  height: 80px;
  position: absolute;
  left: 40px;
}

.borderBox-above {
  border-top: 1px solid;
  border-right: 1px solid;
  border-left: 1px solid;
  top: 40px;
}

.borderBox-below {
  border-bottom: 1px solid;
  border-left: 1px solid;
  bottom: 40px;
  width: 360px;
}

.subEds {
  position: absolute;
  bottom: 10px;
  left: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
}