.errorNotice {
  background: #20282f;
  color: #fcfcfc;
  padding: 20px;
  width: calc(100% - 80px);
  margin: 24px auto;
}

.errorNotice h2 {
  color: #ce482d;
}