@media print {
  body * {
    visibility:hidden;
  }

  body, html {
    background: #fff;
    width: 8.5in;
    padding: 0;
    margin: 0;
  }

  .address-labels, .address-labels * {
    visibility: visible;
  }

  .modal-content.auto-height {
    width: 100%;
    padding: 0;
    margin: 0;
    max-height: max-content;
    overflow: visible;
    background: #fff;
    color: #000;
  }

  .page {
    height: 10.5in;
    width: 8.125in;
    padding: .375in 0 0;
  }

  .modal {
    height: auto;
  }

  .addy {
    width: 33%;
    height: 1in;
    overflow: hidden;
    margin: 0;
    padding: 0;
    display: inline-block;
  }

  .addy-wrapper {
    padding: .0275in .2625in;
    font-size: 15px;
    line-height: 17px;
  }

  .addy-wrapper.small {
    font-size: 14px;
    line-height: 16px;
  }

  .addy-wrapper.smallest {
    font-size: 12px;
    line-height: 13px;
  }
}
